import {
  AppBar,
  Box,
  Button,
  CardMedia,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { BiMenu } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { Link as RouterLink } from "react-router-dom";

import logo from "../assets/images/logo.png";

const navItems = [
  { id: 2, name: "Мыслишки", link: "thoughts" },
  { id: 3, name: "О нас", link: "about" },
  { id: 4, name: "Процесс", link: "howwework" },
  { id: 5, name: "География", link: "wherewework" },
  { id: 6, name: "FAQ", link: "faq" },
  { id: 7, name: "Контакты", link: "contact" },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    display: "none",
    [theme.breakpoints?.down("sm")]: {
      display: "block",
    },
  },
  navLinks: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-around",
    [theme.breakpoints?.down("sm")]: {
      display: "none",
    },
  },
}));

const Navbar = React.memo(() => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScrollPos && currentScrollPos > 67) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(!drawerOpen);
  };

  const classes = useStyles();

  const list = () => (
    <Box
      sx={{
        width: 250,
        pt: 4,
        color: "white",
        fontFamily: "Orbitron, sans-serif",
        background: "linear-gradient(180deg, #10101a, #1e1e3f)",
      }}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        {navItems.map((item) => (
          <ListItem Button key={item.id}>
            <RouterLink to={item.link} onClick={toggleDrawer}>
              <ListItemText
                primary={item.name}
                disableTypography
                sx={{
                  cursor: "pointer",
                  textTransform: "uppercase",
                  width: "100%",
                  textDecoration: "none",
                  color: "#0FF",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  transition: "color 0.3s",
                  "&:hover": {
                    color: "#F0F",
                  },
                }}
              />
            </RouterLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        top: showNavbar ? 0 : "-67px",
        transition: "top 0.3s ease-in-out",
        background: "linear-gradient(90deg, #9400D3, #FF007F, #1e1e3f)",
        boxShadow: "0px 0px 15px 5px rgba(255, 0, 255, 0.5)",
        backdropFilter: "blur(5px)",
      }}
    >
      <Toolbar sx={{ px: 4 }}>
        {/* Logo */}
        <Typography
          variant="h6"
          component={RouterLink}
          to="/"
          sx={{
            cursor: "pointer",
            textDecoration: "none",
            flexGrow: 1,
          }}
        >
          <CardMedia
            src={logo}
            alt="Logo"
            component="img"
            sx={{
              width: 40,
              height: 40,
              filter: "drop-shadow(0px 0px 10px #0FF)",
            }}
          />
        </Typography>

        {/* Navigation Links for Desktop */}
        <Box
          className={classes.navLinks}
          sx={{ display: { xs: "none", lg: "block" } }}
        >
          {navItems.map((item) => (
            <Button
              fontWeight="bold"
              component={RouterLink}
              key={item.id}
              to={item.link}
              sx={{
                cursor: "pointer",
                textTransform: "uppercase",
                textDecoration: "none",
                color: "#0FF",
                fontSize: "1rem",
                fontWeight: "bold",
                transition: "color 0.3s",
                "&:hover": {
                  color: "#F0F",
                },
              }}
            >
              {item.name}
            </Button>
          ))}
        </Box>

        {/* Hamburger Menu for Mobile */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer()}
          className={classes.menuButton}
          sx={{
            display: { xs: "block", lg: "none" },
            fontSize: 32,
            color: "#0FF",
            "&:hover": {
              color: "#F0F",
            },
          }}
        >
          <BiMenu />
        </IconButton>

        {/* Drawer */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer()}
          PaperProps={{
            sx: {
              background: "linear-gradient(180deg, #10101a, #1e1e3f)",
              backdropFilter: "blur(10px)",
              color: "white",
            },
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close drawer"
            onClick={toggleDrawer()}
            sx={{
              position: "absolute",
              top: 10,
              right: 15,
              color: "#FFF",
              "&:hover": {
                color: "#F0F",
              },
            }}
          >
            <CgClose size={24} />
          </IconButton>

          {list()}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
});

export default Navbar;
