import React from "react";

import HeroSection from "../components/Home/HeroSection";

function Home() {
  return (
    <>
      <HeroSection />
    </>
  );
}

export default Home;
