import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Thoughts from "./pages/Thoughts";
import SideNavbar from "./components/SideNavbar";
import { Box } from "@mui/material";

function App() {
  return (
    <>
      {/* Browser router */}
      <BrowserRouter>
        {/* <Navbar /> */}
        {/* <SideNavbar /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/thoughts" element={<Thoughts />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
