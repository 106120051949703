import { createTheme } from "@mui/material/styles";

// Define the colors
const colors = {
  background: {
    dark: "#191A23", // Dominant dark background
    darker: "#181B23", // Slightly lighter dark shade
  },
  accent: {
    blue: "#00FFFF", // Neon blue
    violet: "#9400D3", // Neon violet
    pink: "#FF007F", // Neon pink
    teal: "#00FFD1", // Neon teal
  },
};

// Create the theme
const theme = createTheme({
  palette: {
    mode: "dark", // Enable dark mode
    background: {
      default: colors.background.dark,
      paper: colors.background.darker,
    },
    primary: {
      main: colors.accent.blue, // Neon blue as the primary color
    },
    secondary: {
      main: colors.accent.pink, // Neon pink as the secondary color
    },
    error: {
      main: colors.accent.violet, // Use violet for error accents
    },
    success: {
      main: colors.accent.teal, // Neon teal for success messages
    },
    text: {
      primary: colors.accent.blue, // White text
      secondary: colors.accent.pink,
    },
  },
  typography: {
    fontFamily: "'Orbitron', 'Jura', sans-serif",
    // color: colors.accent.violet,
    h1: {
      fontFamily: "'Orbitron','Saira Stencil One', 'Stalinist One', sans-serif",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "'Orbitron','Saira Stencil One', 'Stalinist One', sans-serif",
      fontWeight: 700,
    },
    h3: {
      fontFamily: "'Orbitron','Saira Stencil One', 'Stalinist One', sans-serif",
      fontWeight: 700,
    },
    h4: {
      fontFamily: "'Saira Stencil One', 'Stalinist One', sans-serif",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "'Orbitron','Saira Stencil One', 'Stalinist One', sans-serif",
      fontWeight: 700,
    },
    h6: {
      fontFamily: "'Orbitron','Saira Stencil One', 'Stalinist One', sans-serif",
      fontWeight: 700,
    },
    body1: {
      fontFamily: "'Orbitron', 'Jura', sans-serif",
      fontWeight: 400,
    },

    button: {
      fontFamily: "'Orbitron', 'Jura', sans-serif",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Rounded edges
          fontWeight: "bold",
          textTransform: "uppercase",
          // backgroundColor: colors.accent.blue, // Default button color
          // color: "white",
          // "&:hover": {
          //   backgroundColor: colors.accent.teal, // Violet on hover
          //   color: "#000",
          // },
        },
        contained: {
          // backgroundColor: colors.accent.violet,
          // // color: "white",
          // "&:hover": {
          //   backgroundColor: colors.accent.blue,
          //   color: "black",
          // },
        },
        outlined: {
          borderColor: colors.accent.violet,
          color: colors.accent.violet,
          "&:hover": {
            backgroundColor: colors.accent.violet,
            color: "white",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.accent.pink,
          "&:hover": {
            textDecoration: "underline",
            color: colors.accent.blue,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.accent.blue, // Default text color
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "background.darker",
          // background: "none",
          boxShadow: "none",
          maxWidth: "lg",
          margin: "0 auto",
        },
      },
    },
  },
});

export default theme;
