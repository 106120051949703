import { Box, Button } from "@mui/material";
import React from "react";

import {
  FaSpotify,
  FaApple,
  FaYoutube,
  FaSoundcloud,
  FaInstagram,
  FaTiktok,
  FaTelegramPlane,
} from "react-icons/fa";

import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const LinkTree = () => {
  const links = [
    {
      label: "urban_freestyler",
      color: "#C13584",
      url: "https://instagram.com/urban_freestyler",
      icon: <FaInstagram size={20} />,
    },
    {
      label: "urban_freestyler",
      color: "#2AABEE",
      url: "https://t.me/urban_freestyler",
      icon: <FaTelegramPlane size={20} />,
    },
    {
      label: "bes1sh",
      color: "#14171A",
      url: "https://x.com/bes1sh",
      icon: <FaXTwitter size={20} />,
    },
    // {
    //   label: "TikTok",
    //   color: "#000000",
    //   url: "https://tiktok.com",
    //   icon: <FaTiktok size={20} />,
    // },
    // {
    //   label: "Spotify",
    //   color: "#1DB954",
    //   url: "https://spotify.com",
    //   icon: <FaSpotify size={20} />,
    // },
    // {
    //   label: "Apple Music",
    //   color: "#FA203C",
    //   url: "https://music.apple.com",
    //   icon: <FaApple size={20} />,
    // },
    {
      label: "@urban_freestyler",
      color: "#FF0000",
      url: "https://youtube.com/@urban_freestyler",
      icon: <FaYoutube size={20} />,
    },
    {
      label: "urbanfreestyler",
      color: "#FF8800",
      url: "https://soundcloud.com/urbanfreestyler",
      icon: <FaSoundcloud size={20} />,
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        position: "relative",
        // background: "#1E1E3F",
        // minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: 4,
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "250px",
          maxWidth: "80vw",
          zIndex: 2,
        }}
      >
        {/* Connect the buttons with lines from the sides */}
        <Box
          sx={{
            position: "absolute",
            width: "calc(100% + 60px)",
            height: "calc(100% - 32px)",
            top: "16px",
            left: "-30px",
            display: "flex",
            borderRadius: 4,
            borderColor: "primary.main",
            borderWidth: "2px",
            borderStyle: "solid",
            flexDirection: "column",
            justifyContent: "space-evenly",
            zIndex: -1,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "calc(50% - 2px",
              left: "-30px",
              width: "30px",
              height: "2px",
              background: "primary.main",
              zIndex: -1,
            }}
          />
        </Box>
        {Array(links.length - 1)
          .fill(0)
          .map(
            (_, index) =>
              index > 0 && (
                <Box
                  key={index}
                  sx={{
                    position: "absolute",
                    width: "calc(100% + 60px)",
                    height: "2px",
                    top: `calc((32px + 16px) * ${index} + 16px)`,
                    left: "-30px",
                    borderColor: "primary.main",
                    borderTop: "2px solid",
                    zIndex: -1,
                  }}
                />
              )
          )}

        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            zIndex: -1,
          }}
        >
          {Array(links.length - 1)
            .fill(0)
            .map((_, index) => (
              <Box
                key={index}
                // component="span"
                sx={{
                  width: "2px",
                  height: "calc(100% / 8)",
                  backgroundColor: "primary.main",
                }}
              />
            ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {links.map((link, index) => (
            <Button
              key={index}
              component={Link}
              variant="text"
              to={link.url}
              target="_blank"
              // onClick={() => window.open(link.url, "_blank")}
              size="small"
              sx={{
                height: "32px",
                borderRadius: "50px",
                backgroundColor: link.color,
                fontSize: "12px",
                fontWeight: "bold",
                color: "#fff",
                "&:hover": {
                  scale: 1.02,
                  boxShadow: "0px 0px 15px 5px rgba(255, 255, 255, 0.3)",
                },
                transition: "all 0.3s ease-in-out",
              }}
              fullWidth
              startIcon={link.icon}
            >
              {link.label}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default LinkTree;
