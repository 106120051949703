import { Box, Typography } from "@mui/material";

import cyberpunkBg from "../../assets/images/cpbg2.png";
import cyberpunkBgMobile from "../../assets/images/cpbgm.png";
import CountdownTimer from "./CountdownTimer";
import MusicPlayer from "./MusicPlayer";

import frankocean from "../../assets/audio/frankocean.mp3";
import kendrick from "../../assets/audio/kendrick.mp3";
import rodwave from "../../assets/audio/rodwave.mp3";
import ArtistHeader from "./CyberpunkTypography";
import LinkTree from "./LinkTree";

// Usage Example:
const tracks = [
  {
    title: "Kendrick Lamar",
    cover: "https://via.placeholder.com/150",
    duration: "3:45",
    mp3: kendrick,
  },
  {
    title: "Rod Wave",
    cover: "https://via.placeholder.com/150",
    duration: "2:20",
    mp3: rodwave,
  },
  {
    title: "Frank Ocean",
    cover: "https://via.placeholder.com/150",
    duration: "4:10",
    mp3: frankocean,
  },
];

function HeroSection() {
  const newYearDate = new Date("January 1, 2025 00:00:00").getTime();

  return (
    <Box
      sx={{
        py: 5,
        minHeight: "100vh",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "center",
        color: "accent.violet",

        background: "background.dark",
        backgroundImage: {
          xs: `url(${cyberpunkBgMobile})`,
          md: `url(${cyberpunkBg})`,
        },
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* <ArtistHeader artistName={"Urban Freestyler"} /> */}

      {/* City Name */}
      <Typography
        variant="h1"
        sx={{
          pt: 8,
          fontSize: { xs: "2.5rem", md: "5rem" },
          fontWeight: "bold",
          textTransform: "uppercase",
          letterSpacing: "0.1em",
          color: "text.primary", // Base color hidden for the gradient
          backgroundImage: "linear-gradient(90deg, #0ff, #f0f, #0ff)",
          backgroundClip: "text",
          // textFillColor: "transparent",
          textShadow: `
      0 0 5px rgba(0, 255, 255, 0.7),
      0 0 10px rgba(0, 255, 255, 0.6),
      0 0 20px rgba(240, 0, 255, 0.5),
      0 0 40px rgba(240, 0, 255, 0.4)`,
          animation: "cyberTextGlow 2s infinite alternate ease-in-out",
          "@keyframes cyberTextGlow": {
            "0%": {
              textShadow: `
          0 0 5px rgba(0, 255, 255, 0.9),
          0 0 10px rgba(0, 255, 255, 0.7),
          0 0 20px rgba(240, 0, 255, 0.5),
          0 0 40px rgba(240, 0, 255, 0.4)`,
            },
            "100%": {
              textShadow: `
          0 0 10px rgba(0, 255, 255, 0.7),
          0 0 20px rgba(0, 255, 255, 0.5),
          0 0 30px rgba(240, 0, 255, 0.4),
          0 0 50px rgba(240, 0, 255, 0.3)`,
            },
          },
        }}
      >
        urban freestyler
      </Typography>

      {/* Countdown */}
      {/* <CountdownTimer targetDate={newYearDate} /> */}
      {/* <MusicPlayer tracks={tracks} /> */}
      <LinkTree />
    </Box>
  );
}

export default HeroSection;
