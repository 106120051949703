import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const CyberpunkTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Orbitron, sans-serif", // More impactful font
  fontWeight: 700,
  textTransform: "uppercase",
  letterSpacing: "0.2em", // More pronounced letter spacing
  textShadow: `0 0 8px ${theme.palette.secondary.main}, 0 0 16px ${theme.palette.secondary.dark}`, // Use secondary color for a different vibe
  animation: "flicker 1.5s linear infinite", // More subtle, rhythmic animation

  "&:hover": {
    animationPlayState: "paused",
  },

  "@keyframes flicker": {
    "0%": { opacity: 0.8 },
    "5%": { opacity: 0.6 },
    "10%": { opacity: 1 },
    "15%": { opacity: 0.7 },
    "20%": { opacity: 0.9 },
    "25%": { opacity: 0.6 },
    "30%": { opacity: 1 },
    "100%": { opacity: 1 },
  },
}));

const NeonBox = styled(Box)(({ theme }) => ({
  padding: 4,
  marginTop: 4,
  marginBottom: 4,
  my: 4,
  borderRadius: "8px",
  background:
    "radial-gradient(ellipse at center, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%)",
  boxShadow: `0 0 15px ${theme.palette.secondary.main}, 0 0 30px ${theme.palette.secondary.dark}`,
  border: `2px solid ${theme.palette.secondary.light}`, // Thicker border
  textAlign: "center",
  position: "relative", // For pseudo-element
  overflow: "hidden", // Hide overflow for the pseudo-element
  "&::before": {
    // Added a pseudo-element for a light streak
    content: '""',
    position: "absolute",
    top: "-50%",
    left: "-50%",
    width: "200%",
    height: "200%",
    background: `linear-gradient(to bottom right, transparent, ${theme.palette.secondary.light}, transparent)`,
    transform: "rotate(-45deg)",
    animation: "streak 3s linear infinite",
    opacity: 0.3,
  },
  "@keyframes streak": {
    "0%": { transform: "rotate(-45deg) translate(-50%, -50%)" },
    "100%": { transform: "rotate(-45deg) translate(50%, 50%)" },
  },
}));

const ArtistHeader = ({ artistName }) => {
  return (
    <NeonBox>
      <CyberpunkTypography variant="h4" color="secondary">
        {artistName}
      </CyberpunkTypography>
    </NeonBox>
  );
};

export default ArtistHeader;
