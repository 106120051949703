import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

const ThoughtCard = ({ thought }) => {
  return (
    <Card
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${thought.image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // width: "100%",
        height: "400px",
        maxHeight: "calc(100vw - 40px)",
        borderRadius: 2,
        borderWidth: "2px",
        borderColor: "#00ffff", // Neon cyan border
        borderStyle: "solid",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* <CardContent /> */}
      <CardContent
        sx={{
          py: 4,
          px: 2,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          // alignItems: "center",
          height: "100%",
          color: "#ffffff", // White text for readability
          fontFamily: "'Share Tech Mono', monospace", // Cyberpunk-style font
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            mt: 1,
            fontStyle: "italic",
            // borderLeft: "4px solid #00ffff", // Neon cyan accent
            borderLeftWidth: "4px",
            borderLeftStyle: "solid",
            borderLeftColor: "primary.main",
            paddingLeft: "10px",
            margin: "10px 0",
            color: "#00ffff",
            textShadow: "0px 0px 8px #00ffff",
          }}
        >
          {thought.quote}
        </Typography>
        <Typography variant="body2">{thought.thoughts}</Typography>
      </CardContent>
    </Card>
  );
};

export default ThoughtCard;
